import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Alert, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faMapMarkerAlt, faUsers, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';

const ReportDetails = () => {
  const { id } = useParams();
  const [report, setReport] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(`https://hiredsafe.com/PHP/get_report_by_id.php?id=${id}`);
        console.log('Fetched report:', response.data); // Log the response data to debug

        if (response.data.status === 'success') {
          setReport(response.data.data); // Set the report data from the response
        } else {
          setError(response.data.message); // Handle error message from the API
        }
      } catch (error) {
        setError('An error occurred while fetching the report');
        console.error('Error fetching report:', error);
      }
    };

    fetchReport();
  }, [id]);

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
        <div className="text-center">
          <Button href="/" variant="primary">
            Back to Reports
          </Button>
        </div>
      </Container>
    );
  }

  if (!report) {
    return (
      <Container className="py-5 text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Container>
    );
  }

  return (
    <>
      <style>{`
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f4f4f4;
        }
        header {
          background-color: #fff;
          padding: 15px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
        }
        .content {
          justify-content: space-between;
          padding: 30px;
          background-color: #fff;
          max-width: 1800px;
          margin: 30px auto;
        }
        .company-info {
          flex: 2;
          margin-right: 30px;
        }
        .company-info h1 {
          font-size: 28px;
          margin-bottom: 30px;
          color: #333;
        }
        .fraud-history {
          background-color: #78909c;
          color: #fff;
          padding: 30px;
          border-radius: 10px;
          width: 1058px;
          height: 627px;
          top: 297px;
          gap: 0px;
          border-radius: 5px 0px 0px 0px;
        }
        .fraud-history h2 {
          font-size: 22px;
          margin-bottom: 20px;
        }
        .fraud-history ul {
          list-style: none;
          padding: 0;
        }
        .fraud-history li {
          margin-bottom: 25px;
          font-size: 18px;
          white-space: pre-wrap; /* Preserve formatting */
          max-height: 500px; /* Set max height */
          overflow: auto; /* Enable scrolling when content overflows */
        }

        /* Hide scrollbar but allow scrolling */
        .fraud-history li::-webkit-scrollbar {
          display: none;
        }

        .fraud-history li {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }

        .fraud-history span {
          font-size: 16px;
          color: #ccc;
        }
        .company-details {
          flex: 1;
          text-align: left;
        }
        .company-img {
          width: 100%;
          border-radius: 10px;
          margin-bottom: 20px;
        }
        .company-details h3 {
          font-size: 22px;
          margin-bottom: 20px;
          color: #333;
        }
        .company-details p {
          margin: 15px 0;
          font-size: 18px;
          color: #333;
        }
        .company-details i {
          margin-right: 10px;
          color: #3e7d92;
        }
      `}</style>

      <Container className="content mt-5">
        <Row>
          <Col md={7}>
            <div className="company-info">
              <h1><b>{report.COMPANY_NAME}</b></h1>
              <div className="fraud-history">
                <h2>Fraud History</h2>
                <ul>
                  <span>Reported on {new Date(report.DATEOFSCAM).toLocaleDateString()}</span>
                  {report.DESCRIPTION_OF_SCAM ? (
                    <li>
                      {report.DESCRIPTION_OF_SCAM}
                    </li>
                  ) : (
                    <li>No fraud history reported.</li>
                  )}
                </ul>
              </div>
            </div>
          </Col>
          <Col md={1}></Col>

          <Col md={4}>
            <div className="company-details">
              {report.EVIDENCE && (
                <Image 
                  variant="top" 
                  src={report.EVIDENCE} 
                  alt="Evidence" 
                  fluid
                />
              )}

              <h3><br />Company Details</h3>
              <Row>
                <Col>
                  <p><FontAwesomeIcon icon={faLaptopCode} /> Industry:<b>{report.INDUSTRY}</b></p>
                  <p><FontAwesomeIcon icon={faMapMarkerAlt} /> Location : <b>{report.COMPANY_ADRESS}</b></p>
                </Col>
                <Col>
                  <p><FontAwesomeIcon icon={faUsers} /> Reported by <b>{report.REPORTER_NAME}</b></p>
                  <p><FontAwesomeIcon icon={faPhoneAlt} /> Industry Contact : <b>{report.industry_contact}</b></p>
                </Col>
                <Button href="/" variant="primary">
                  Back to Reports
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportDetails;
