import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Home from './component/pages/Home';
import ReportScam from './component/pages/ReportScam';
import Contactus from './component/pages/Contactus';
import Navbar1 from './component/pages/common/Navbar';
import Footer from './component/pages/common/Footor';
import BlogPage from './component/pages/BlogPage';
import BlogDetails from './component/pages/Blog-details';
import ReportDetails from './component/pages/ReportDetail1';
import AdminDashboard from './component/pages/Adminpages/AdminDashboard';
import BlogDetailsMore from './component/pages/Blog-detailsMore';
import ReportsPage from './component/pages/ReportsPage';
import SearchResults from './component/pages/SearchResults';
import AdminLogin from './component/pages/Adminpages/AdminLogin';
import AdminBlogPage from './component/pages/Adminpages/AdminBlogPage';
import ProtectedRoute from './component/pages/common/ProtectedRoute';
import About from './component/pages/About';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation(); // Hook to get the current location (URL)
  const [isAuthenticated, setIsAuthenticated] = useState(false);  // Manage authentication
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [error, setError] = useState(null); // Handle errors
  const [showResults, setShowResults] = useState(false); // Show search results state

  const navigate = useNavigate(); // Allows programmatic navigation

  // Define the onLogin function to set the authentication state
  const onLogin = (status) => {
    setIsAuthenticated(status);  // Update the authentication state
  };

  // Handle the search function for the Navbar
  const handleSearch = (query) => {
    // Check if the query is empty and return early if so
    if (!query || query.trim() === '') {
      setSearchResults([]); // Clear the search results
      setShowResults(false); // Hide the results
      setError(null); // Clear any errors
      return;
    }

    // Proceed with the search if query is not empty
    fetch(`https://hiredsafe.com/PHP/search.php?query=${query}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setSearchResults([]); // Clear the search results on error
        } else {
          setSearchResults(data); // Set the fetched data to state
          setShowResults(true); // Show the results
          setError(null); // Clear any previous errors
        }
      })
      .catch((error) => {
        setError('An error occurred while fetching search results.');
        setSearchResults([]); // Clear previous results in case of an error
        setShowResults(false); // Hide results if there's an error
      });
  };

  // Function to clear the search results
  const clearSearchResults = () => {
    setSearchResults([]); // Clear results
    setShowResults(false); // Hide the results
  };

  // Clear search results when the route changes
  useEffect(() => {
    clearSearchResults();
  }, [location]); // Run this effect when the location (URL) changes

  // Determine whether to show the Navbar (hide it on admin pages and login page)
  const isAdminPage = location.pathname.startsWith('/admin') || location.pathname === '/adminlogin';

  // Determine whether to show the Footer (hide it on admin dashboard page)
  const showFooter = !location.pathname.startsWith('/admin');

  return (
    <div>
      {/* Render the Navbar only if it's not an admin or login page */}
      {!isAdminPage && <Navbar1 onSearch={handleSearch} />}

      <div>
        <div className="container mt-5">
          {/* Display search results only if showResults is true */}
          {showResults && (
            <>
              <button className="btn btn-primary mb-3" onClick={clearSearchResults}>
                Clear Results ×
              </button>
              <SearchResults results={searchResults} />
            </>
          )}

          {/* Display error message if there's an error */}
          {error && <p className="text-danger">{error}</p>}
        </div>
      </div>

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/reportscam" element={<ReportScam />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/createblog" element={<BlogPage />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/reportdetails/:id" element={<ReportDetails />} />
        <Route path="/blog-details/:id" element={<BlogDetailsMore />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/search" element={<SearchResults results={searchResults} />} />

        {/* Admin Login Route */}
        <Route path="/adminlogin" element={<AdminLogin onLogin={onLogin} />} /> {/* Pass onLogin prop */}

        {/* Protected Routes */}
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adminblog"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AdminBlogPage />
            </ProtectedRoute>
          }
        />
      </Routes>

      {/* Render the Footer only if it's not an admin page */}
      {showFooter && <Footer />}
    </div>
  );
}

export default App;
