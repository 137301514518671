import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom'; // Import useParams to get the blog ID
import HashLoader from "react-spinners/HashLoader";

const BlogDetailsMore = () => {
  const { id } = useParams(); // Extract the blog ID from the URL
  const [blog, setBlog] = useState(null); // State to store the fetched blog
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://hiredsafe.com/PHP/get_blog_by_id.php?id=${id}`);
        console.log('Fetched Blog:', response.data); // Log the response data
        setBlog(response.data);
      } catch (error) {
        console.error('There was an error fetching the blog:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlog();
  }, [id]); // Add id to the dependency array to fetch the correct blog when the ID changes

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <HashLoader color="blue" />
      </Container>
    );
  }

  if (!blog) {
    return (
      <Container className="py-5 text-center">
        <p>No blog found.</p>
      </Container>
    );
  }

  return (
    <div>
      <main>
        {/* Blog Details Section */}
        <section className="blog-details py-5" style={{ backgroundColor: '#e0f7fa' }}>
          <Container>
            <Row>
              <Col md={12}>
                <Card className="post-detail" style={{ textAlign: 'center' }}>
                  <Card.Body>
                    <h2 style={{ color: '#287094', textAlign: 'center' }}>{blog.blog_title || 'Untitled Blog'}</h2>
                    <Card.Text className="author" style={{ textAlign: 'center' }}>
                      By {blog.author_name || 'Unknown Author'} | Published on {blog.created_at ? new Date(blog.created_at).toLocaleDateString() : 'N/A'}
                    </Card.Text>

                    {/* Center and resize the image */}
                    <div className="post-image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
                      <Image 
                        src={blog.upload || 'placeholder-image-url.jpg'}  // Fallback if image is missing
                        alt="Blog Image" 
                        fluid 
                        style={{ width: '600px', height: '400px', objectFit: 'cover' }} // Reduced width and height, centered
                      />
                    </div>

                    {/* Center the description container but keep the text left-aligned */}
                    <Card.Text style={{ 
                      marginTop: '20px', 
                      whiteSpace: 'pre-wrap', 
                      maxWidth: '800px',  // Constrain width of the description
                      marginLeft: 'auto', // Center the container horizontally
                      marginRight: 'auto', // Center the container horizontally
                      textAlign: 'left', // Keep the text left-aligned
                      maxHeight: '900px', 
                      overflow: 'auto' 
                    }}>
                      {blog.blog_description || 'No description available.'}
                    </Card.Text>

                    {/* Hide the scrollbar but allow scrolling */}
                    <style jsx>{`
                      .post-detail .card-text::-webkit-scrollbar {
                        display: none;
                      }
                      .post-detail .card-text {
                        -ms-overflow-style: none;  /* IE and Edge */
                        scrollbar-width: none;  /* Firefox */
                      }
                    `}</style>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </div>
  );
};

export default BlogDetailsMore;
