import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Image, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Blog_Hero from "../assets/bloghero.jpg";
import HashLoader from "react-spinners/HashLoader";

const BlogDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://hiredsafe.com/PHP/get_blogs.php');
        console.log('Fetched Blogs:', response.data);
        setBlogs(response.data);
      } catch (error) {
        console.error('There was an error fetching the blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const displayedBlogs = showAll ? blogs : blogs.slice(0, 6);

  return (
    <div>
      <main>
        <section className="blog-intro py-5" style={{ backgroundColor: '#e0f7fa' }}>
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <div className="blog-intro-content">
                  <h2 style={{ fontSize: '36px', color: '#287094', marginBottom: '20px' }}>
                    Empower Your Job Search with Knowledge and Insights
                  </h2>
                  <p style={{ marginBottom: '20px', color: '#333' }}>
                    Welcome to the HiredSafe Blog, your trusted resource for navigating the job market with confidence and security. Here, we provide expert tips, in-depth articles, and real-life stories to help you identify and avoid job scams, understand industry trends, and make informed career decisions. Whether you're a fresh graduate stepping into the workforce or an experienced professional seeking new opportunities, our blog is dedicated to empowering you with the knowledge and tools you need to stay ahead in the ever-evolving job market. Stay safe, stay informed, and embark on your search with HiredSafe by your side.
                  </p>
                  <a href="/createblog" className="button" style={buttonStyle}>Write Blog</a>
                </div>
              </Col>
              <Col md={6} className="text-center">
                <div className="blog-intro-image">
                  <img src={Blog_Hero} alt="Blog Introduction Image" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Blog Section */}
        <section className="blog py-5" style={{ backgroundColor: '#287094' }}>
          <Container>
            <h3 className="mb-4 text-white">From the Blog</h3>
            <Row className="blog-posts">
              {displayedBlogs.map((blog) => {
                const isHiredSafeBlog = blog.author_name === 'Admin'; // Check if the blog is authored by HiredSafe
                return (
                  <Col md={4} className="mb-4" key={blog.id}>
                    <Card className={`h-100 shadow-sm ${isHiredSafeBlog ? 'hiredsafe-blog' : ''}`} style={isHiredSafeBlog ? { border: '2px solid white' } : {}}>
                      <div className="post-image">
                        <Image
                          src={blog.upload || 'placeholder-image-url.jpg'}
                          alt="Blog Image"
                          fluid
                          style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                        />
                      </div>
                      <Card.Body>
                        <Card.Text className="author">
                          <span style={isHiredSafeBlog ? hiredSafeNameStyle : {}}>
                            {isHiredSafeBlog ? 'Blog by HiredSafe' : blog.author_name || 'Unknown Author'}
                          </span>
                          {isHiredSafeBlog && <Badge className="ms-2" style={{ backgroundColor: '#00bfff', color: 'white' }}>HiredSafe</Badge>}
                        </Card.Text>
                        <Card.Text className="date">
                          Published on {blog.created_at ? new Date(blog.created_at).toLocaleDateString() : 'N/A'}
                        </Card.Text>
                        <Card.Title style={isHiredSafeBlog ? { color: '#00bfff' } : {}}>{blog.blog_title || 'Untitled Blog'}</Card.Title>
                        <Card.Text>
                          {blog.blog_description && blog.blog_description.length > 150
                            ? `${blog.blog_description.substring(0, 150)}...`
                            : 'No description available'}
                        </Card.Text>
                        <Link to={`/blog-details/${blog.id}`} className="read-more" style={isHiredSafeBlog ? { color: '#00bfff' } : {}}>Read More</Link>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>

      </main>
    </div>
  );
};

export default BlogDetails;

// Inline styles
const buttonStyle = {
  display: 'inline-block',
  padding: '10px 20px',
  backgroundColor: '#287094',
  color: '#fff',
  textDecoration: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s',
  textAlign: 'center'
};

// Inline style for "HiredSafe" name
const hiredSafeNameStyle = {
  fontWeight: 'bold',
  color: '#00bfff', // Sky blue color
  fontSize: '16px',
  marginRight: '5px'
};
