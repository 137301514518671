import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';  // Import Row and Col for layout
import axios from 'axios';

const BlogPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    title: '',
    description: '',
    file: null,
  });

  const [error, setError] = useState(''); // State for error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if all required fields are filled
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.title.trim() ||
      !formData.description.trim()
    ) {
      setError('Please fill in all required fields.');
      return; // Stop submission if any required field is empty
    }

    // Clear the error message if all fields are filled
    setError('');

    try {
      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        data.append(key, formData[key]);
      });

      const response = await axios.post('https://hiredsafe.com/PHP/submit_blog.php', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.data.status === 'success') {
        alert('Blog submitted successfully');
        // Clear the form after successful submission
        setFormData({
          name: '',
          email: '',
          companyName: '',
          title: '',
          description: '',
          file: null,
        });
      } else {
        alert(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('There was an error submitting the blog:', error);
      alert('There was an error submitting the blog.');
    }
  };

  return (
    <>
      <main>
        <section className="blog-form-section">
          <Container>
            <h2 className="text-center my-4">Write a Blog</h2>
            <Form className="blog-form" onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Display error message */}
              {error && <p className="text-danger">{error}</p>}
              
              <Row>
                <Col md={6}>
                  <Form.Group controlId="your-name" className="mb-3">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="your-email" className="mb-3">
                    <Form.Label>Your Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group controlId="company-name" className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the company name involved in the report (if any)"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="blog-title" className="mb-3">
                    <Form.Label>Title of The Blog</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the title of the blog"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="blog-description" className="mb-3">
                <Form.Label>Description of Blog</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Provide detailed description of the blog"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="upload" className="mb-3">
                <Form.Label>Upload an Image (optional)</Form.Label>
                <Form.Control type="file" name="file" onChange={handleFileChange} />
              </Form.Group>

              <div className="d-grid gap-2">
                <Button variant="primary" type="submit" className="submit-btn">
                  Submit Blog
                </Button>
              </div>
            </Form>
          </Container>
        </section>
      </main>
    </>
  );
};

export default BlogPage;
